import { load } from '@fingerprintjs/botd';

import {
  DebouncedImpressionManager,
  wrapExecutor,
} from '../impression-manager';
import { logger } from '../sentry/console';
import { SessionStorage, Storage } from '../storage';

interface CallCounter {
  incrementTriggerCount(): number;
  triggerCount: number;
}

export class UserAttributes {
  private botD = null;
  private mouseMoveD = new EventDetect('mousemove', 'd-mm');
  private clickD = new EventDetect('click', 'd-cl');
  private scrollD = new EventDetect('scroll', 'd-scrl');

  sessionZoneCallCount: CallCounter = new DebouncedImpressionManager(
    'fetch-zone-session',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    wrapExecutor(() => {}),
    {
      frequency: 0,
      maxExecs: 0,
      period: 0,
      storage: SessionStorage,
    }
  );

  zoneCallCount: CallCounter = new DebouncedImpressionManager(
    'fetch-zone',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    wrapExecutor(() => {})
  );

  constructor() {
    if (this.isBot() === null) {
      load({ monitoring: false })
        .then((botD) => {
          this.botD = botD;
          return this.botD.detect();
        })
        .then((result) => {
          Storage.setParam('bot-d', result.bot);
          if (result.bot) {
            Storage.setParam('bot-k', result.botKind);
          }
        })
        .catch((e) => {
          logger.error(e);
        });
    }
  }

  isBot() {
    const value = Storage.getParam('bot-d');

    if (!value || value === 'null') {
      return null;
    }

    return value === 'true';
  }

  botKind() {
    return Storage.getParam('bot-k');
  }

  getIsBotQueryString(): null | string {
    if (this.isBot() === null) {
      return null;
    }

    let botType = '';

    if (this.isBot()) {
      botType = `:${this.botKind()}`;
    }

    return `tt=${Number(this.isBot())}${botType}`;
  }

  getQueryParamsString(): string {
    const params = [
      `depth=${this.zoneCallCount.triggerCount}:${this.sessionZoneCallCount.triggerCount}`,
      `events=${this.clickD.emittedNr()}${this.mouseMoveD.emittedNr()}${this.scrollD.emittedNr()}`,
    ];

    const isBotQueryString = this.getIsBotQueryString();

    if (isBotQueryString) {
      params.push(isBotQueryString);
    }

    return params.join('&');
  }
}

class EventDetect {
  event: string = null;
  storageKey: string = null;

  constructor(event: string, storageKey: string) {
    if (!event || !storageKey) {
      throw new Error('event, storageKey required');
    }

    this.handler = this.handler.bind(this);

    this.event = event;
    this.storageKey = storageKey;

    if (!Storage.getParam(this.storageKey)) {
      document.addEventListener(this.event, this.handler, false);
    }
  }

  handler() {
    Storage.setParam(this.storageKey, true);
    document.removeEventListener(this.event, this.handler, false);
  }

  emitted() {
    return Storage.getParam(this.storageKey) === 'true';
  }

  emittedNr() {
    return Number(this.emitted());
  }
}
