import { RequestParamsProviderFactory } from '../api/request-params-provider-factory';
import { impressionNumberState } from '../experiment';
import Tracker from '../tracker';
import { AdType } from '../types';

import { IBannerApiZone } from './i-zone';
import { Zone } from './zone';

export class Banner extends Zone {
  private zone: IBannerApiZone;
  private static tracker = Tracker.getInstance();
  private static requestParams =
    RequestParamsProviderFactory.NewRequestParamsProvider(
      (impressionNumberState && AdType.Html) || undefined
    );

  constructor(zone: IBannerApiZone) {
    super();

    this.zone = zone;
  }

  get hasPlainCode(): boolean {
    return !!this.zone?.config?.plainCode;
  }

  public insert(spot: HTMLElement) {
    this.zone.creative &&
      Banner.requestParams.withCreatives([this.zone.creative]);

    const container = this.createContainer();

    this.dom.replaceChild(container, spot);

    Banner.requestParams.incrementImpressionNumber();

    Banner.tracker.addItemToTrackList({
      el: container,
      tr: this.zone.tr,
    });

    Banner.requestParams.withCreatives([]);
    // logger.debug(`insert code: ${this.zone.code} for zoneId: ${this.zone.id}`);
    return;
  }

  createCode(): string {
    if (this.hasPlainCode) {
      return this.zone.config.plainCode as string;
    }

    return this.zone.code.replace(
      '{params}',
      '&' + Banner.requestParams.toString()
    );
  }

  createContainer(): HTMLElement {
    const code = this.createCode();
    return this.dom.createElement(code).children[0] as HTMLElement;
  }
}
