interface CustomEventParams {
  bubbles: boolean;
  cancelable: boolean;
  detail: unknown;
}

(function () {
  if (typeof window.CustomEvent === 'function') return;

  function CustomEvent(
    event: string,
    params = {
      bubbles: false,
      cancelable: false,
      detail: null,
    } as CustomEventParams
  ): CustomEvent<unknown> {
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  // @ts-ignore
  window.CustomEvent = CustomEvent;
})();
