/**
 * Throttling enforces a maximum number of times a function
 * can be called over time.
 *
 * @param func a function
 * @param timeout
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(func: Function, timeout: number): Function {
  let ready = true;
  return (...args) => {
    if (!ready) {
      return;
    }

    ready = false;
    func(...args);
    setTimeout(() => {
      ready = true;
    }, timeout);
  };
}
