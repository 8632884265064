import 'regenerator-runtime/runtime';
import './polyfill';
import { Renderer } from './renderer';
import { RemoteLogger, runSentry } from './sentry';
import { loadAdBlockChecker, makeCustomPublicPath, onDOMLoaded } from './utils';

function injectRenderer(r: Renderer) {
  if (window['hptRdr'] instanceof Renderer) {
    return;
  }
  window['hptRdr'] = r;
}

(function () {
  makeCustomPublicPath();

  onDOMLoaded(async function () {
    runSentry();

    try {
      const LOAD_CHECKER = !!window.hpRdrConfig?.useCheckerLoader;

      if (LOAD_CHECKER) {
        loadAdBlockChecker().then(() => injectRenderer(new Renderer()));
      } else {
        injectRenderer(new Renderer());
      }
    } catch (e) {
      RemoteLogger.getInstance().captureEvent(e);
    }
  });
})();

export {};
