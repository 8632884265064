import { Breadcrumb } from '@sentry/types';

import { Storage } from '../storage';

export default {
  release: 'renderer-ts@' + process.env.version,
  dsn: 'https://7006024f77744aecab8936c724422a05@sentry.aechannel.com/4',
  sampleRate: 1,
  tracesSampleRate: 0,
  maxBreadcrumbs: 10,
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  initialScope: (scope) => {
    const sessionId = Storage.getCookie('__ae_uid_sess_id') || 'not specified';
    scope.setTags({ sessionId: sessionId });
    return scope;
  },
  beforeBreadcrumb: (breadcrumb: Breadcrumb): Breadcrumb | null => {
    const okLevels = ['warning', 'error', 'critical'];
    const okLevel = okLevels.includes(breadcrumb.level);

    return okLevel ? null : breadcrumb;
  },
  integrations: (integrations) => {
    return integrations.filter((integration) => {
      return (
        integration.name !== 'GlobalHandlers' ||
        integration.name !== 'LinkedErrors'
      );
    });
  },
};
