export const logger = {
  isExist(methodName: string) {
    return console && console[methodName];
  },
  log(...args) {
    if (!logger.isExist('log')) return;
    console.log(...args);
  },
  warn(...args) {
    if (!logger.isExist('warn')) return;
    console.warn(...args);
  },
  error(...args) {
    if (!logger.isExist('error')) return;
    console.error(...args);
  },
  debug(...args) {
    if (!logger.isExist('debug')) return;
    console.debug(...args);
  },
};
