import { MediaTypes } from '../types';

export interface IQueryParams {
  [key: string]: string;
}

export function getQueryParams(qs: string): IQueryParams {
  qs = qs.split('+').join(' ');

  const params: IQueryParams = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;

  let tokens;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export function isSessionStorageSupported() {
  const storage = window.sessionStorage;
  try {
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

export function generateUuidv4() {
  // @ts-ignore
  const myCrypto = window.crypto || window.msCrypto;
  if (myCrypto) {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
      return (
        c ^
        (myCrypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    });
  } else {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}

export function checkLocalStorage() {
  try {
    localStorage.setItem('chk', 'chk');
    localStorage.removeItem('chk');
    return true;
  } catch (e) {
    return false;
  }
}

export function checkSessionStorage() {
  try {
    sessionStorage.setItem('chk', 'chk');
    sessionStorage.removeItem('chk');
    return true;
  } catch (e) {
    return false;
  }
}

let getAdblockCache: boolean | null = null;

export function getAdBlock(): boolean {
  if (getAdblockCache !== null) return getAdblockCache;

  const ads = document.createElement('div');
  ads.innerHTML = '&nbsp;';
  ads.className =
    'ad ads adsbox doubleclick ad-placement ad-placeholder adbadge BannerAd';
  getAdblockCache = false;
  try {
    // body may not exist, that's why we need try/catch
    document.body.appendChild(ads);
    getAdblockCache =
      (
        document.getElementsByClassName(
          'ad ads adsbox doubleclick ad-placement ad-placeholder adbadge BannerAd'
        ) as HTMLCollectionOf<HTMLElement>
      )[0].offsetHeight === 0;
    document.body.removeChild(ads);
  } catch (e) {
    getAdblockCache = false;
  }
  return getAdblockCache;
}

export function onDOMLoaded(callback) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    callback();
  }
}

export function makeCustomPublicPath() {
  const hpRdr = document.getElementById('hpt-rdr');
  const customPublicPath = hpRdr?.getAttribute('data-static-path');

  if (customPublicPath) {
    __webpack_public_path__ = customPublicPath + '/rdrr/';
  }
}

export const supportsHLS = (function () {
  const supportsHLSCache = null;

  return function (): boolean {
    if (typeof supportsHLSCache === 'boolean') {
      return supportsHLSCache;
    }

    const video = document.createElement('video');
    return Boolean(video.canPlayType(MediaTypes.MimeTypeHls));
  };
})();

export function loadAdBlockChecker() {
  const CHECKER_DEFAULT_URL = 'https://stats.hprofits.com/advertisement.min.js';
  const CHECKER_URL_CONFIG = window.hpRdrConfig
    ? window.hpRdrConfig.checkerUrl
    : false;
  const CHECKER =
    CHECKER_URL_CONFIG && typeof CHECKER_URL_CONFIG === 'string'
      ? CHECKER_URL_CONFIG
      : CHECKER_DEFAULT_URL;

  return new Promise<void>((resolve) => {
    const newScript = document.createElement('script');
    newScript.src = CHECKER;
    newScript.onload = function () {
      resolve();
    };

    newScript.onerror = function () {
      resolve();
    };
    document.body.appendChild(newScript);
  });
}
