import { get } from '../http';
import { getQueryParams } from '../utils';

import { RequestParamsProvider } from './request-params-provider';

export class DirectPaidTrafficTracker extends RequestParamsProvider {
  static trackerBaseUrl = 'https://hip-97166b.com/event?unit=897';

  constructor() {
    super();

    if (DirectPaidTrafficTracker.isPaidTraffic) {
      this.sendTracker();
    }
  }

  sendTracker() {
    get(DirectPaidTrafficTracker.trackerBaseUrl + '&' + this.getParams());
  }

  getParams(): string {
    const reqId = Math.ceil(Math.random() * 100000000000000);
    const n = +new Date();

    return (
      this.toString() + `&c=0&did=&id=1&mid=0&noscript=0&reqid=${reqId}&n=${n}`
    );
  }

  static get isPaidTraffic(): boolean {
    const entryQueryParams = getQueryParams(window.location.search);
    const hasRequiredParams = !!(
      entryQueryParams['utm_medium'] &&
      entryQueryParams['utm_source'] &&
      entryQueryParams['utm_campaign']
    );

    return hasRequiredParams;
  }
}
