/**
 * Get parameters required by adserver
 */
import { RemoteLogger } from '../sentry';
import { Storage } from '../storage';
import { AdType } from '../types';
import { Utm } from '../user/utm';
import {
  generateUuidv4,
  getAdBlock,
  getQueryParams,
  IQueryParams,
  isSessionStorageSupported,
} from '../utils';

export class RequestParamsProvider {
  utmSession: Utm;
  _impressionNumberKey: AdType = null;
  _creatives: string[];
  _params: IQueryParams;
  static _userAttrs = null;

  constructor(creatives = [], userAttrs = null) {
    this.utmSession = new Utm();
    if (userAttrs && !RequestParamsProvider._userAttrs) {
      RequestParamsProvider._userAttrs = userAttrs;
    }
    this._creatives = [...creatives];
    this._params = getQueryParams(window.location.search) || {};

    if (this._params['__site']) {
      if (isSessionStorageSupported()) {
        window.sessionStorage.setItem('__site', this._params['__site']);
      }

      return;
    }

    if (isSessionStorageSupported()) {
      const val = window.sessionStorage.getItem('__site');
      if (val && val.length > 0) {
        this._params['__site'] = val;
      }
    }
  }

  setImpressionNumberKey(name: AdType): RequestParamsProvider {
    this._impressionNumberKey = name;
    return this;
  }

  get page(): string {
    return this._params['__site'] || window.location.href;
  }

  get referrer(): string {
    return this._params['__site'] || document.referrer;
  }

  get userId(): string {
    const userId = Storage.getParam('__ae_uid');
    if (userId) {
      return userId;
    }

    Storage.setParam('__ae_uid', this.sessionId);
    return this.sessionId;
  }

  get sessionId(): string {
    const STORE_KEY = '__ae_uid_sess_id';
    const uid = Storage.getSessionParam(STORE_KEY) || generateUuidv4();

    Storage.setSessionParam(STORE_KEY, uid);

    return uid;
  }

  get adblock(): { canRunAdvertise: boolean; canRunAds: boolean } {
    return {
      canRunAdvertise: window.canRunAdvertise,
      canRunAds: window.canRunAds,
    };
  }

  get frame(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  get timezone(): number {
    return new Date().getTimezoneOffset();
  }

  get impressionNumber(): number {
    if (!this._impressionNumberKey) return 0;

    let num = +Storage.getCookie(`imp-num-${this._impressionNumberKey}`);

    if (isNaN(num)) {
      num = 1;
    }

    return num;
  }

  incrementImpressionNumber(): void {
    if (!this._impressionNumberKey) return;
    Storage.setCookie(
      `imp-num-${this._impressionNumberKey}`,
      this.impressionNumber + 1
    );
  }

  toString(): string {
    const params = [
      ...this.utmSession.getUtmState(),
      `p=${encodeURIComponent(this.page)}`,
      `r=${encodeURIComponent(this.referrer)}`,
      // `p=https://24pornvideo.tv`,
      // `r=https://24pornvideo.tv`,
      `uid=${this.userId.split('-').join('')}`,
      `frm=${+this.frame}`,
      `tz=${this.timezone}`,

      // three parameters were created to check different methods of adblock detection
      // adblk, ablk, a. first two influence on response code and last one not
      `adblk=${+!this.adblock.canRunAds}`,
      `ablk=${+!this.adblock.canRunAdvertise}`,
      `a=${+!getAdBlock()}`,
      `rv=${process.env.version}`,
      // `debugip=73.187.236.85`,
    ];

    if (RequestParamsProvider._userAttrs) {
      params.push(RequestParamsProvider._userAttrs.getQueryParamsString());
    } else {
      RemoteLogger.getInstance().captureEvent(new Error('no user attrs'));
    }

    if (this._impressionNumberKey) {
      params.push(`num=${this.impressionNumber}`);
    }

    if (this._creatives.length > 0) {
      params.push(`cr=${this._creatives.join(';')}`);
    }

    return params.join('&');
  }

  withCreatives(creatives: string[]) {
    this._creatives = creatives;
  }

  static addTimestamp(url: string): string {
    return `${url}&n=${Date.now()}`;
  }

  withNativeParams(url: string): string {
    let newUrl = url;

    if (window?.hpNativeConfig?.customCount) {
      if (window.hpNativeConfig.rows && window.hpNativeConfig.itemsPerRow) {
        newUrl +=
          '&nrc=' +
          window.hpNativeConfig.rows +
          '&npr=' +
          window.hpNativeConfig.itemsPerRow;
      }
    }

    return newUrl;
  }
}
