import { RequestParamsProviderFactory } from '../api/request-params-provider-factory';
import { get } from '../http';
import ImpressionManager from '../impression-manager';

import { IApiTextLinkZone } from './i-zone';
import { Zone } from './zone';

export class TextLink extends Zone {
  private zone: IApiTextLinkZone;
  private static requestParams =
    RequestParamsProviderFactory.NewRequestParamsProvider(undefined);

  constructor(zone: IApiTextLinkZone) {
    super();
    this.zone = zone;
  }

  isRawHtmlSpot(spot: HTMLElement): boolean {
    return spot.getAttribute('data-raw-html') === '1';
  }

  isAutoImpression(spot: HTMLElement): boolean {
    return spot.getAttribute('data-a-i') === '1';
  }

  insertHTML(spot: HTMLElement) {
    const container = this.dom.createElement(this.zone.config.code)
      .children[0] as HTMLElement;

    this.dom.replaceChild(container, spot);
  }

  public insert(spot: HTMLElement) {
    if (this.isRawHtmlSpot(spot)) {
      const im = new ImpressionManager('ml-collector', {
        exec: () => {
          this.insertHTML.apply(this, [spot]);
          get(`${this.zone.code}&${TextLink.requestParams.toString()}`);
        },
      });
      im.do();
      return;
    }

    if (this.isAutoImpression(spot)) {
      this.insertHTML.apply(this, [spot]);
      get(`${this.zone.code}&${TextLink.requestParams.toString()}`);
      return;
    }

    let fullUrl = this.zone.source;
    if (fullUrl.indexOf('p=') === -1) {
      const sep = fullUrl.indexOf('?') === -1 ? '?' : '&';

      fullUrl = `${fullUrl}${sep}p=${encodeURIComponent(window.location.href)}`;

      if (this.zone.creative) {
        fullUrl = fullUrl + `${sep}cr=${this.zone.creative}`;
      }
    }

    spot.insertAdjacentHTML(
      'afterbegin',
      `<a href='${fullUrl}' class='top-link' target='_blank'>${this.zone.config.title}</a>`
    );

    this.dom.cleanupSpotAttrs(spot);
  }

  static invoke(): void {
    const textLinks = document.getElementsByClassName(
      'js-hp-tl'
    ) as HTMLCollectionOf<HTMLAnchorElement>;
    if (textLinks.length === 0) return;

    const p = 'p=' + encodeURIComponent(window.location.href);

    for (let i = 0; i < textLinks.length; i++) {
      let url = null;
      let nestedLink = null;

      if (textLinks[i].href) {
        url = textLinks[i].href;
      } else {
        const link = textLinks[i].getElementsByTagName('a');
        if (link.length && link[0].href) {
          nestedLink = link[0];
          url = link[0].href;
        } else {
          continue;
        }
      }

      if (url.indexOf('p=') === -1) {
        const prefix = url.indexOf('?') === -1 ? '?' : '&';

        if (nestedLink === null) {
          textLinks[i].href = url + prefix + p;
        } else {
          nestedLink.href = url + prefix + p;
        }
      }
    }
  }
}
