import { checkLocalStorage, checkSessionStorage } from './utils';

interface CookieOptions {
  'max-age'?: number;
  path?: string;
  expires?: string | Date;
}

export class Storage {
  static setCookie(
    name,
    value: string | number | boolean,
    options?: CookieOptions
  ) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires === 'session') {
      delete options.expires;
    }

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  static getCookie(name): string | undefined {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    if (match) return match[2];
    return undefined;
  }

  static deleteCookie(name) {
    Storage.setCookie(name, '', {
      'max-age': -1,
    });
  }

  static getParam(name) {
    if (checkLocalStorage()) {
      return localStorage.getItem(name);
    } else {
      return Storage.getCookie(name);
    }
  }

  static setParam(name, value) {
    if (checkLocalStorage()) {
      return localStorage.setItem(name, value);
    } else {
      return Storage.setCookie(name, value);
    }
  }

  static deleteParam(name) {
    if (checkLocalStorage()) {
      return localStorage.removeItem(name);
    } else {
      return Storage.deleteCookie(name);
    }
  }

  static getSessionParam(name) {
    if (checkSessionStorage()) {
      return sessionStorage.getItem(name);
    } else {
      return Storage.getCookie(name);
    }
  }

  static setSessionParam(name, value) {
    if (checkSessionStorage()) {
      return sessionStorage.setItem(name, value);
    } else {
      return Storage.setCookie(name, value, { expires: 'session' });
    }
  }

  static deleteSessionParam(name) {
    if (checkSessionStorage()) {
      return sessionStorage.removeItem(name);
    } else {
      return Storage.deleteCookie(name);
    }
  }
}

export class SessionStorage {
  static getParam(name) {
    if (checkLocalStorage()) {
      return sessionStorage.getItem(name);
    } else {
      return Storage.getCookie(name);
    }
  }

  static setParam(name, value) {
    if (checkLocalStorage()) {
      return sessionStorage.setItem(name, value);
    } else {
      return Storage.setCookie(name, value);
    }
  }

  static deleteParam(name) {
    if (checkLocalStorage()) {
      return sessionStorage.removeItem(name);
    } else {
      return Storage.deleteCookie(name);
    }
  }
}
