import { Storage } from './storage';
import { throttle } from './utils/throttle';

type impressionManagerOptions = {
  maxExecs: number;
  period: number;
  frequency: number;
  storage?: StorageInterface;
};

interface Executor {
  exec: () => unknown;
}

const defaultOptions: impressionManagerOptions = Object.freeze({
  maxExecs: 1,
  period: 86400, // one day
  frequency: 1, // exec on every impression
});

export const wrapExecutor = (executor: () => unknown): Executor => {
  return {
    exec: executor,
  };
};

interface StorageInterface {
  getParam(name): string;
  setParam(name, value): void;
}

export default class ImpressionManager {
  storage: StorageInterface = Storage;
  private opts: impressionManagerOptions;
  private readonly cookieTriggerCount: string;
  private executor: Executor;

  constructor(namespace: string, executor: Executor, opts = defaultOptions) {
    this.cookieTriggerCount = `${namespace}-im-sc`;
    this.opts = { ...opts };

    this.executor = executor;

    if (opts.storage) {
      this.storage = opts.storage;
    }
  }

  get triggerCount(): number {
    const count = parseInt(this.storage.getParam(this.cookieTriggerCount));
    if (isNaN(count)) return 0;

    return isNaN(count) ? 0 : count;
  }

  incrementTriggerCount(): number {
    const count = this.triggerCount;

    this.storage.setParam(this.cookieTriggerCount, String(count + 1));

    return count + 1;
  }

  get canDo(): boolean {
    const executeTimesByFrequency = this.opts.maxExecs * this.opts.frequency;

    return this.triggerCount < executeTimesByFrequency;
  }

  public do() {
    if (!this.canDo) {
      return;
    }

    const newTriggerCount = this.incrementTriggerCount();

    if (newTriggerCount % this.opts.frequency === 0) {
      return this.executor.exec();
    }
  }
}

export class DebouncedImpressionManager extends ImpressionManager {
  debounceTimer = 2000;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incrementTriggerCount: any = undefined;

  constructor(namespace: string, executor: Executor, opts = defaultOptions) {
    super(namespace, executor, opts);

    this.incrementTriggerCount = throttle(() => {
      super.incrementTriggerCount();
    }, this.debounceTimer);
  }
}
