export enum AdType {
  Popunder = 'popunder',
  InlineScript = 'inline-script',
  Vast = 'vast',
  Interstitial = 'interstitial',
  VideoSlider = 'video-slider',
  VideoOutstream = 'video-outstream',
  Native = 'native',
  NativeHprofits = 'native-hp',
  NativeExo = 'native-exo',
  Push = 'push',
  TextLink = 'text-link',
  Html = 'html',
}

export interface IInserter {
  insert(spot: HTMLElement): void;
}

export enum MediaTypes {
  MimeTypeMP4 = 'video/mp4',
  MimeTypeHls = 'application/vnd.apple.mpegURL',
}
