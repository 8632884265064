export class ExperimentState {
  static getExperimentState(name: string): boolean {
    let newState = Math.random() < 0.5;

    try {
      const localStateString = sessionStorage.getItem(`experiment-${name}`);
      if (localStateString && localStateString.length > 0) {
        newState = 'true' === localStateString;
      }

      // If experiment is active, so then set again. If not, so then set new one calculated state
      sessionStorage.setItem(`experiment-${name}`, String(newState));
      return newState;
    } catch (e) {
      return false;
    }
  }
}

export const impressionNumberState =
  ExperimentState.getExperimentState('impressionNumber');
