import { logger } from './sentry/console';

export const get = function (url, cb?) {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', url, true);
  xhr.send();

  xhr.onreadystatechange = function (): void {
    if (xhr.readyState !== 4) return;
    if (xhr.status === 200) {
      try {
        if (cb && typeof cb === 'function') cb(xhr.responseText);
      } catch (e) {
        logger.warn('failed request: ' + url + ' with error: ' + e);
        return;
      }
    }
  }.bind(this);
};

export const getJSON = function (url, cb?) {
  get(url, (res) => {
    try {
      if (cb && typeof cb === 'function') cb(JSON.parse(res));
    } catch (e) {
      logger.warn('failed request: ' + url + ' with error: ' + e);
      return;
    }
  });
};
